define('m14/controllers/verstaerkung', ['exports', 'ember', 'ember-validations', 'ember-i18n'], function (exports, Ember, EmberValidations, ember_i18n) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend(EmberValidations['default'], {
    debug: false,
    openFromFile: false,
    c26: false,
    displayErrors: true,
    material: {},
    honk: null,
    ttHoeheHT: Ember['default'].computed('i18n.locale', function () {

      return this.get('i18n').t('wertebereich') + "6.0 - 999.0 [cm]";
    }),
    ttBreiteHT: Ember['default'].computed('i18n.locale', function () {

      return this.get('i18n').t('wertebereich') + "3.0 - 999.0 [cm]";
    }),
    ttProfilHoehe: Ember['default'].computed('i18n.locale', function () {

      return this.get('i18n').t('wertebereich') + "6.0 - 999.0 [cm]";
    }),
    ttProfilBreite: Ember['default'].computed('i18n.locale', function () {

      return this.get('i18n').t('wertebereich') + "0.1 - 4.0 [cm]";
    }),

    validations: {
      htbreite: {
        numericality: {
          greaterThanOrEqualTo: 3.0,
          lessThanOrEqualTo: 999.0
        }
      },
      hthoehe: {
        numericality: {
          greaterThanOrEqualTo: 6.0,
          lessThanOrEqualTo: 999.0
        }
      },
      profilBreite: {
        numericality: {
          greaterThanOrEqualTo: 0.1,
          lessThanOrEqualTo: 15
        }
      },
      profilHoehe: {
        numericality: {
          greaterThanOrEqualTo: 1.0,
          lessThanOrEqualTo: 999.0
        }
      }
    },

    i18n: Ember['default'].inject.service(),

    stahlSelected: false,
    holzSelected: true,

    verstaerkungsanordnungen: [],
    verstaerkungsanordnung: 1,
    verstaerkungsmaterialien: [],
    verstaerkungsmaterial: true,
    stahlquerschnitte: [],
    stahlquerschnitt: false,
    stahlgueten: [],
    stahlguete: 23.5,
    profilklassen: [],
    profilklasse: 3,
    hoelzer: [],
    holz: 0,
    fklassen: [],
    fkl: 5,

    htbreite: "",
    hthoehe: "",
    profilBreite: "0.0",
    profilHoehe: "0.0",
    htbreitetmp: "",
    hthoehetmp: "",
    profilBreitetmp: "1.0",
    profilHoehetmp: "12.0",

    profilstahlDisabled: false,
    flachstahlDisabled: true,
    bereichsweiseVerstaerkungDisabled: true,

    vgebohrt: [],
    teReIVorgebohrt: false,
    teReILaubholz: false,

    te2vorgebohrtHilfetext: "",
    te2vorgebohrtHilfetextFocused: false,

    initTrigger: false,

    init: function init() {
      var self = this;
      this._super();

      var applicationdata = this.controllerFor('application').get('model').application.objectAt(0);

      var lang = applicationdata.get('countrycode');
      var einschraubtiefen = "einschraubtiefen";

      this.setSelectFieldsContent();
      // this.set('verstaerkungsanordnungen', verstaerkungsanordnungDB.verstaerkungsanordnung_de);
      this.set('verstaerkungsanordnung', 1);

      // this.set('verstaerkungsmaterialien', verstaerkungsmaterialDB.verstaerkungsmaterial_de);
      this.set('verstaerkungsmaterial', true);

      // this.set('stahlquerschnitte', stahlquerschnitteDB.stahlquerschnitte_de);
      this.set('stahlquerschnitt', false);

      // this.set('stahlgueten', fklDB.fkl_stahl);
      this.set('stahlguete', 23.5);

      // this.set('profilklassen', profilklassenDB.profilklassen_de);
      this.set('profilklasse', 3);

      // this.set('hoelzer', self.getMaterials([0, 1, 13, 30, 12]));
      this.set('holz', 0);

      // this.set('fklassen', fklDB.fkl_vh);
      this.set('fkl', 5);

      this.set('profilHoehe', "12.0");
      this.set('profilBreite', "1.0");
    },

    setValues: function setValues(values) {

      var self = this;

      var htbreiteEvent = {
        target: {
          name: "htbreite"
        }
      };
      var hthoeheEvent = {
        target: {
          name: "hthoehe"
        }
      };

      var profilBreiteEvent = {
        target: {
          name: "profilBreite"
        }
      };
      var profilHoeheEvent = {
        target: {
          name: "profilHoehe"
        }
      };

      this.set('openFromFile', true);

      this.set('systemlaenge', values.LSys);

      this.set('verstaerkungsanordnung', parseInt(values.nBauteile));
      this.set('verstaerkungsmaterial', values.VerstaerkungHolz);
      this.set('holz', parseInt(values.MatKeyVerstaerkungstraeger));
      this.set('fkl', parseInt(values.FKLKeyVerstaerkungstraeger));

      this.set('teReIVorgebohrt', values.Verstaerkungstraeger_vorgebohrt);

      this.set('stahlquerschnitt', values.Flachstahl);
      this.set('stahlguete', parseFloat(values.f_yk));

      if (values.VerstaerkungHolz === true) {

        this.set('htbreite', values.breite_ReI);
        this.setX3D(Number(values.breite_ReI), htbreiteEvent);

        this.set('hthoehe', values.hoehe_ReI);
        this.setX3D(Number(values.hoehe_ReI), hthoeheEvent);
      } else {
        this.set('profilklasse', profilklassenDB.profilklassen_de.findBy('name', values.ProfilName).id);

        this.set('profilBreite', values.profilBreite);
        this.set('profilHoehe', values.profilHoehe);

        this.setX3D(Number(values.profilBreite), profilBreiteEvent);
        this.setX3D(Number(values.profilHoehe), profilHoeheEvent);
      }

      this.send('validation');

      this.set('openFromFile', false);
    },

    setSelectFieldsContent: function setSelectFieldsContent() {
      var self = this;
      self.setVerstaerkungsanordnungen();
      self.setVerstaerkungsmaterialien();
      self.setStahlquerschnitte();
      self.setStahlgueten();
      self.setProfilklassen();
      self.setHoelzer();
      self.setFklassen();
      self.setVgebohrt();
    },

    setVerstaerkungsanordnungen: function setVerstaerkungsanordnungen() {
      var self = this;
      var indices = [1, 2];
      self.set('verstaerkungsanordnungen', self.getSelectFieldContent('verstaerkungsanordnungen', indices));
    },

    setVerstaerkungsmaterialien: function setVerstaerkungsmaterialien() {
      var self = this;
      var indices = [true, false];
      self.set('verstaerkungsmaterialien', self.getSelectFieldContent('verstaerkungsmaterialien', indices));
    },

    setStahlquerschnitte: function setStahlquerschnitte() {
      var self = this;
      var indices = [false, true];
      self.set('stahlquerschnitte', self.getSelectFieldContent('stahlquerschnitte', indices));
    },

    setStahlgueten: function setStahlgueten() {
      var self = this;
      var indices = [23.5, 35.5];
      self.set('stahlgueten', self.getSelectFieldContent('stahlgueten', indices));
    },

    setProfilklassen: function setProfilklassen() {
      var self = this;
      var indices = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16];
      self.set('profilklassen', self.getSelectFieldContent('profilklassen', indices));
    },

    setHoelzer: function setHoelzer() {
      var self = this;
      var indices = [0, 1, 13, 30, 12];
      self.set('hoelzer', self.getSelectFieldContent('hoelzer', indices));
    },

    setFklassen: function setFklassen(indices) {
      var self = this;
      var indices = [];

      if (this.get('holz') === 1) {
        indices = [20, 21, 24, 22];
        self.set('fklassen', self.getSelectFieldContent('fkl_bsh', indices));
        self.set('fkl', 20);
      } else if (self.get('holz') === 13) {
        indices = [30, 31, 34, 32];
        self.set('fklassen', self.getSelectFieldContent('fkl_bsh_kombi', indices));
        self.set('fkl', 30);
      } else if (self.get('holz') === 0) {
        indices = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
        self.set('fklassen', self.getSelectFieldContent('fkl_vh', indices));
        self.set('fkl', 5);
      } else if (self.get('holz') === 30) {
        indices = [301];
        self.set('fklassen', self.getSelectFieldContent('fkl_baubuche', indices));
        self.set('fkl', 301);
      } else if (self.get('holz') === 12) {
        indices = [50, 51, 52];
        self.set('fklassen', self.getSelectFieldContent('fkl_vollholz_laubholz', indices));
        self.set('fkl', 50);
      }
    },

    setVgebohrt: function setVgebohrt() {
      var self = this;
      var indices = [false, true];
      self.set('vgebohrt', self.getSelectFieldContent('vgebohrt', indices));
    },

    getSelectFieldContent: function getSelectFieldContent(db, indexes) {
      var self = this;
      var tmp = [];
      var dbContent = self.getDBContent(db);
      for (var i = 0; i < indexes.length; i++) {
        for (var k = 0; k < dbContent.length; k++) {
          if (dbContent[k].id === indexes[i] && db !== 'stahlgueten' && db !== "profilklassen") {
            tmp.push({ id: dbContent[k].id, name: this.get('i18n').t(dbContent[k].name), disabled: dbContent[k].disabled });
          } else if (dbContent[k].id === indexes[i] && db === 'stahlgueten') {
            tmp.push({ id: dbContent[k].id, name: this.get('i18n').t(dbContent[k].name), disabled: dbContent[k].disabled, TextIndex: dbContent[k].name });
          } else if (dbContent[k].id === indexes[i] && db === 'profilklassen') {
            tmp.push({ id: dbContent[k].id, name: dbContent[k].name, disabled: dbContent[k].disabled, TextIndex: dbContent[k].name });
          }
        }
      }

      return tmp;
    },

    getDBContent: function getDBContent(db) {
      var dbContent = [];
      switch (db) {
        case 'verstaerkungsanordnungen':
          dbContent = verstaerkungsanordnungDB.verstaerkungsanordnung_de;
          break;
        case 'verstaerkungsmaterialien':
          dbContent = verstaerkungsmaterialDB.verstaerkungsmaterial_de;
          break;
        case 'stahlquerschnitte':
          dbContent = stahlquerschnitteDB.stahlquerschnitte_de;
          break;
        case 'stahlgueten':
          dbContent = fklDB_new.fkl_stahl.fklassen;
          break;
        case 'profilklassen':
          dbContent = profilklassenDB.profilklassen_de;
          break;
        case 'hoelzer':
          dbContent = materialienDB.materialien_de_m03;
          break;
        case 'hoelzer':
          dbContent = materialienDB.materialien_de_m03;
          break;
        case 'fkl_vh':
          dbContent = fklDB_new.fkl_vh.fklassen;
          break;
        case 'fkl_bsh':
          dbContent = fklDB_new.fkl_bsh.fklassen;
          break;
        case 'fkl_bsh_kombi':
          dbContent = fklDB_new.fkl_bsh_kombi.fklassen;
          break;
        case 'fkl_baubuche':
          dbContent = fklDB_new.fkl_baubuche.fklassen;
          break;
        case 'fkl_vollholz_laubholz':
          dbContent = fklDB_new.fkl_vollholz_laubholz.fklassen;
          break;
        case 'vgebohrt':
          dbContent = vorgebohrt.auswahlfeld;
          break;
      }
      return dbContent;
    },

    getValueFromSelectField: function getValueFromSelectField(contentArray, index) {
      var self = this;

      var tmp = "";
      var len = contentArray.length;

      for (var i = 0; i < len; i++) {
        if (contentArray[i].id === index) {
          tmp = contentArray[i].name;
        }
      }
      return tmp;
    },

    watchNumericalInputs: (function () {

      if (!this.openFromFile) {

        if (this.get('htbreite').indexOf(",") !== -1) {
          this.set('htbreite', this.get('htbreite').replace(",", "."));
        }

        if (this.get('hthoehe').indexOf(",") !== -1) {
          this.set('hthoehe', this.get('hthoehe').replace(",", "."));
        }
      }
    }).observes('htbreite', 'hthoehe'),

    watchMaterial: (function () {
      var self = this;

      self.set('teReILaubholz', vorgebohrt.materialien.findBy('id', self.get('holz')).laubholz);
      self.set('teReIVorgebohrt', vorgebohrt.materialien.findBy('id', self.get('holz')).value);

      self.setFklassen();
    }).observes('holz'),

    getProfilname: function getProfilname(index) {
      var self = this;
      var pklassenDB = profilklassenDB.profilklassen_de;

      var x3d = self.controllerFor('application').get('model').x3ddefault.objectAt(0);
      var uProfil1 = x3d.get('bauteile').findBy('id', 'uProfil1');

      var tmp = "";
      var len = pklassenDB.length;

      for (var i = 0; i < len; i++) {
        if (pklassenDB[i]["id"] == index) {
          tmp = pklassenDB[i]["name"];

          if (self.get('profilstahlDisabled') === false) {
            self.set('profilBreite', pklassenDB[i]["breite"]);
            self.set('profilHoehe', pklassenDB[i]["hoehe"]);
          }

          uProfil1.get('boxsizes').objectAt(0).set('y', pklassenDB[i]["hoehe"]);
          uProfil1.get('boxsizes').objectAt(0).set('z', pklassenDB[i]["breite"]);
          uProfil1.get('boxsizes').objectAt(0).set('materialdicke', pklassenDB[i]["tw"]);
        }
      }

      self.setLagerBreite();
      x3d.set('transformHelper', !x3d.get('transformHelper'));

      return tmp;
    },

    getStahlname: function getStahlname(index) {
      var self = this;
      var pklassenDB = fklDB_new.fkl_stahl.fklassen;

      var tmp = "";
      var len = pklassenDB.length;

      for (var i = 0; i < len; i++) {
        if (pklassenDB[i]["id"] === index) {
          // tmp = pklassenDB[i]["fkl"];
          tmp = this.get('i18n').t(pklassenDB[i]["name"]);
        }
      }
      return tmp;
    },

    watchVerstaerkungsmaterial: (function () {
      var self = this;
      var x3d = self.controllerFor('application').get('model').x3ddefault.objectAt(0);
      var bauteile = x3d.get('bauteile');

      var geometrie = this.controllerFor('geometrie');

      if (this.get('verstaerkungsmaterial') === true) {
        this.set('holzSelected', true);
        this.set('stahlSelected', false);

        self.set('profilHoehetmp', self.get('profilHoehe'));
        self.set('profilBreitetmp', self.get('profilBreite'));
        self.set('validations.profilHoehe.numericality.greaterThanOrEqualTo', 0.0);
        self.set('validations.profilBreite.numericality.greaterThanOrEqualTo', 0.0);
        self.set('validations.htbreite.numericality.greaterThanOrEqualTo', 3.0);
        self.set('validations.hthoehe.numericality.greaterThanOrEqualTo', 6.0);
        self.set('profilHoehe', '0.0');
        self.set('profilBreite', '0.0');
        self.set('htbreite', self.get('htbreitetmp'));
        self.set('hthoehe', self.get('hthoehetmp'));

        x3d.set('stahlVerstaerkung', false);

        self.send('validation', self.get('htbreite'), {
          target: {
            name: "htbreite"
          }
        });

        bauteile.findBy('id', 'material2').get('diffusecolors').objectAt(0).set('rot', 0.52);
        bauteile.findBy('id', 'material2').get('diffusecolors').objectAt(0).set('gruen', 0.385);
        bauteile.findBy('id', 'material2').get('diffusecolors').objectAt(0).set('blau', 0.02);

        // bauteile.findBy('id', 'material2').get('boxsizes').objectAt(0).set('x', bauteile.findBy('id', 'material1').get('boxsizes').objectAt(0).get('x'));
        if (x3d.get('hoeheMaterial2Eingetragen') === false) {
          bauteile.findBy('id', 'material2').get('boxsizes').objectAt(0).set('y', 20);
        }
        if (x3d.get('breiteMaterial2Eingetragen') === false) {
          bauteile.findBy('id', 'material2').get('boxsizes').objectAt(0).set('z', 10);
        }
      } else if (this.get('verstaerkungsmaterial') === false) {
        this.set('holzSelected', false);
        this.set('stahlSelected', true);

        self.set('htbreitetmp', self.get('htbreite'));
        self.set('hthoehetmp', self.get('hthoehe'));
        self.set('validations.profilHoehe.numericality.greaterThanOrEqualTo', 1.0);
        self.set('validations.profilBreite.numericality.greaterThanOrEqualTo', 0.1);
        self.set('validations.htbreite.numericality.greaterThanOrEqualTo', 0.0);
        self.set('validations.hthoehe.numericality.greaterThanOrEqualTo', 0.0);
        self.set('htbreite', '0.0');
        self.set('hthoehe', '0.0');
        self.set('profilHoehe', self.get('profilHoehetmp'));
        self.set('profilBreite', self.get('profilBreitetmp'));

        self.send('validation', self.get('htbreite'), {
          target: {
            name: "htbreite"
          }
        });

        x3d.set('stahlVerstaerkung', true);
        bauteile.findBy('id', 'material2').get('diffusecolors').objectAt(0).set('rot', 0.4);
        bauteile.findBy('id', 'material2').get('diffusecolors').objectAt(0).set('gruen', 0.4);
        bauteile.findBy('id', 'material2').get('diffusecolors').objectAt(0).set('blau', 1);

        // bauteile.findBy('id', 'material2').get('boxsizes').objectAt(0).set('x', bauteile.findBy('id', 'material1').get('boxsizes').objectAt(0).get('x'));
        if (x3d.get('hoeheMaterial2Eingetragen') === false) {
          bauteile.findBy('id', 'material2').get('boxsizes').objectAt(0).set('y', 10);
        }
        if (x3d.get('breiteMaterial2Eingetragen') === false) {
          bauteile.findBy('id', 'material2').get('boxsizes').objectAt(0).set('z', 2);
        }
      }

      geometrie.setSchraubenlaenge();

      self.setLagerBreite();
      x3d.set('transformHelper', !x3d.get('transformHelper'));
    }).observes('verstaerkungsmaterial'),

    watchStahlquerschnitt: (function () {
      var self = this;
      var x3d = self.controllerFor('application').get('model').x3ddefault.objectAt(0);
      var bauteile = x3d.get('bauteile');

      if (this.get('stahlquerschnitt') === false) {
        this.set('profilstahlDisabled', false);
        this.set('flachstahlDisabled', true);

        x3d.set('istProfilstahl', true);
      } else if (this.get('stahlquerschnitt') === true) {
        this.set('profilstahlDisabled', true);
        this.set('flachstahlDisabled', false);

        this.set("profilBreite", "");
        this.set("profilHoehe", "");

        bauteile.findBy('id', 'material2').get('boxsizes').objectAt(0).set('z', 1);
        x3d.set('istProfilstahl', false);
      }

      var geometrie = this.controllerFor('geometrie');
      geometrie.setSchraubenlaenge();

      self.send('validation', self.get("profilHoehe"), {
        target: {
          name: "profilHoehe"
        }
      });

      self.setLagerBreite();
      x3d.set('transformHelper', !x3d.get('transformHelper'));
    }).observes('stahlquerschnitt'),

    watchComboboxes: (function () {

      var self = this;

      self.controllerFor('supercontroller').resetApplication();

      var verstaerkung = this.controllerFor('application').get('model').verstaerkung.objectAt(0);

      verstaerkung.set('nBauteile', this.get('verstaerkungsanordnung'));
      verstaerkung.set('VerstaerkungHolz', this.get('verstaerkungsmaterial'));
      verstaerkung.set('MatKeyVerstaerkungstraeger', this.get('holz'));
      verstaerkung.set('FKLKeyVerstaerkungstraeger', this.get('fkl'));
      verstaerkung.set('Flachstahl', this.get('stahlquerschnitt'));
      verstaerkung.set('f_yk', this.get('stahlguete'));

      var strLength = self.getStahlname(parseFloat(this.get('stahlguete'))).toString().length;
      verstaerkung.set('Bezeichnung', self.getStahlname(parseFloat(this.get('stahlguete'))).toString().substring(strLength - 6, strLength));
      verstaerkung.set('ProfilName', self.getProfilname(parseInt(this.get('profilklasse'))).toString());

      verstaerkung.set('profilBreite', parseFloat(self.get('profilBreite')).toFixed(1));
      verstaerkung.set('profilHoehe', parseFloat(self.get('profilHoehe')).toFixed(1));

      self.controllerFor('lasteinwirkung').setNklassen();
      self.controllerFor('lasteinwirkung').send('validation', self.controllerFor('lasteinwirkung').get('vorbelastung'), { target: { name: "vorbelastung" } });
    }).observes('verstaerkungsanordnung', 'verstaerkungsmaterial', 'holz', 'fkl', 'stahlquerschnitt', 'stahlguete', 'profilklasse'),

    watchVerstaerkungsanordnung: (function () {

      var self = this;
      var verbundstellen = self.controllerFor('verbundstellen');
      var x3d = self.controllerFor('application').get('model').x3ddefault.objectAt(0);

      if (self.get('verstaerkungsanordnung') === 1 && self.get('verstaerkungsmaterial') === true) {
        // verbundstellen.set('vortriebsrichtungen', vortriebsrichtungenDB.vortriebsrichtungen_de);
        // verbundstellen.set('verbindungsmittelLagen', lageVerbindungsmittelDB.lageVerbindungsmittel_de);
        verbundstellen.set('indexContentTextboxen', 0);
        verbundstellen.set('vortriebsrichtung', true);
        verbundstellen.set('lageVerbindungsmittel', 2);
        x3d.set('zweiseitigeVerstaerkung', false);
      } else if (self.get('verstaerkungsanordnung') === 2 && self.get('verstaerkungsmaterial') === true) {
        // verbundstellen.set('vortriebsrichtungen', vortriebsrichtungenDB.vortriebsrichtungen_de_alternative);
        // verbundstellen.set('verbindungsmittelLagen', lageVerbindungsmittelDB.lageVerbindungsmittel_de);
        verbundstellen.set('indexContentTextboxen', 1);
        verbundstellen.set('vortriebsrichtung', true);
        verbundstellen.set('lageVerbindungsmittel', 2);
        x3d.set('zweiseitigeVerstaerkung', true);
      } else if (self.get('verstaerkungsanordnung') === 1 && self.get('verstaerkungsmaterial') === false) {
        // verbundstellen.set('vortriebsrichtungen', vortriebsrichtungenDB.vortriebsrichtungen_de_alternative);
        // verbundstellen.set('verbindungsmittelLagen', lageVerbindungsmittelDB.lageVerbindungsmittel_de_alternative);
        verbundstellen.set('indexContentTextboxen', 2);
        verbundstellen.set('vortriebsrichtung', true);
        verbundstellen.set('lageVerbindungsmittel', 2);
        x3d.set('zweiseitigeVerstaerkung', false);
      } else if (self.get('verstaerkungsanordnung') === 2 && self.get('verstaerkungsmaterial') === false) {
        // verbundstellen.set('vortriebsrichtungen', vortriebsrichtungenDB.vortriebsrichtungen_de_alternative);
        // verbundstellen.set('verbindungsmittelLagen', lageVerbindungsmittelDB.lageVerbindungsmittel_de_alternative);
        verbundstellen.set('indexContentTextboxen', 2);
        verbundstellen.set('vortriebsrichtung', true);
        verbundstellen.set('lageVerbindungsmittel', 2);
        x3d.set('zweiseitigeVerstaerkung', true);
      }

      verbundstellen.setSelectFieldsContent();
      x3d.set('schraubenlisteAktualisiert', !x3d.get('schraubenlisteAktualisiert'));
    }).observes('verstaerkungsanordnung', 'verstaerkungsmaterial'),

    // setSchraubenlaenge: function(bMaterial1, bMaterial2) {
    //     var application = this.controllerFor('application');
    //     var x3d = application.get('model').x3ddefault.objectAt(0);
    //     var bauteile = application.get('model').x3ddefault.objectAt(0).get('bauteile');
    //
    //     var hoehe = bMaterial1/2 +bMaterial2;
    //
    //     bauteile.forEach(function(item) {
    //       if (item.get('typ') === 'standardSchraube') {
    //
    //         var schraubenLaenge = hoehe / Math.cos(1.5708 - Number(item.get('rotations').objectAt(0).get('z')));
    //
    //         item.get('screwProperties').objectAt(0).set('length', Math.abs(schraubenLaenge));
    //         item.get('screwProperties').objectAt(0).set('threadlengthtip', Math.abs(schraubenLaenge));
    //       }
    //     });
    //   },

    setLagerBreite: function setLagerBreite() {
      var application = this.controllerFor('application');
      var x3d = application.get('model').x3ddefault.objectAt(0);
      var bauteile = application.get('model').x3ddefault.objectAt(0).get('bauteile');

      if (x3d.get('istProfilstahl') && x3d.get('stahlVerstaerkung')) {
        bauteile.findBy('id', 'lager2').get('boxsizes').objectAt(0).set('z', bauteile.findBy('id', 'uProfil1').get('boxsizes').objectAt(0).get('z'));
        bauteile.findBy('id', 'lager22').get('boxsizes').objectAt(0).set('z', bauteile.findBy('id', 'uProfil1').get('boxsizes').objectAt(0).get('z'));
        bauteile.findBy('id', 'lager23').get('boxsizes').objectAt(0).set('z', bauteile.findBy('id', 'uProfil1').get('boxsizes').objectAt(0).get('z'));
        bauteile.findBy('id', 'lager24').get('boxsizes').objectAt(0).set('z', bauteile.findBy('id', 'uProfil1').get('boxsizes').objectAt(0).get('z'));
      } else {
        bauteile.findBy('id', 'lager2').get('boxsizes').objectAt(0).set('z', bauteile.findBy('id', 'material2').get('boxsizes').objectAt(0).get('z'));
        bauteile.findBy('id', 'lager22').get('boxsizes').objectAt(0).set('z', bauteile.findBy('id', 'material2').get('boxsizes').objectAt(0).get('z'));
        bauteile.findBy('id', 'lager23').get('boxsizes').objectAt(0).set('z', bauteile.findBy('id', 'material2').get('boxsizes').objectAt(0).get('z'));
        bauteile.findBy('id', 'lager24').get('boxsizes').objectAt(0).set('z', bauteile.findBy('id', 'material2').get('boxsizes').objectAt(0).get('z'));
      }
    },

    watchTeReIVorgebohrt: (function () {
      var self = this;

      self.resetVerbindungsmittel();

      var verstaerkung = self.controllerFor('application').get('model').verstaerkung.objectAt(0);
      verstaerkung.set('Verstaerkungstraeger_vorgebohrt', self.get('teReIVorgebohrt'));
    }).observes('teReIVorgebohrt'),

    setX3D: function setX3D(value, event) {

      var application = this.controllerFor('application');
      var x3d = application.get('model').x3ddefault.objectAt(0);
      var bauteile = application.get('model').x3ddefault.objectAt(0).get('bauteile');
      var geometrie = this.controllerFor('geometrie');

      var self = this;

      self.controllerFor('geometrie').resetGrafikToDefault();

      switch (event.target.name) {
        case 'htbreite':
          if (this.errors.htbreite.length === 0) {
            x3d.set('breiteMaterial2Eingetragen', true);
            bauteile.findBy('id', 'material2').get('boxsizes').objectAt(0).set('z', Number(value));
            geometrie.setSchraubenlaenge();
            geometrie.setSchraubenPosition();
            self.setLagerBreite();
          } else {
            application.get('model').x3ddefault.objectAt(0).set('breiteMaterial2Eingetragen', false);
          }
          break;
        case 'hthoehe':
          if (this.errors.hthoehe.length === 0) {
            value = Number(self.get('hthoehe'));
            x3d.set('hoeheMaterial2Eingetragen', true);
            bauteile.findBy('id', 'material2').get('boxsizes').objectAt(0).set('y', Number(value));
            geometrie.setSchraubenlaenge();
            geometrie.setSchraubenPosition();
          } else {
            application.get('model').x3ddefault.objectAt(0).set('hoeheMaterial2Eingetragen', false);
          }
          break;
        case 'profilBreite':
          if (this.errors.profilBreite.length === 0) {
            x3d.set('breiteMaterial2Eingetragen', true);
            bauteile.findBy('id', 'material2').get('boxsizes').objectAt(0).set('z', Number(value));
            geometrie.setSchraubenlaenge();
            geometrie.setSchraubenPosition();
            self.setLagerBreite();
          } else {
            application.get('model').x3ddefault.objectAt(0).set('breiteMaterial2Eingetragen', false);
          }
          break;
        case 'profilHoehe':
          if (this.errors.profilHoehe.length === 0) {
            value = Number(self.get('profilHoehe'));
            x3d.set('hoeheMaterial2Eingetragen', true);
            bauteile.findBy('id', 'material2').get('boxsizes').objectAt(0).set('y', Number(value));
          } else {
            application.get('model').x3ddefault.objectAt(0).set('hoeheMaterial2Eingetragen', false);
          }
          break;
      }

      x3d.set('transformHelper', !x3d.get('transformHelper'));
      self.controllerFor('application').zentriertObjekt();
    },

    actions: {

      validation: function validation(value, event) {

        var self = this;

        if (!self.openFromFile) {

          this.setX3D(value, event);
        }

        self.validate().then(function () {
          // all validations pass

        })['catch'](function () {
          // any validations fail

        })['finally'](function () {

          var validation = self.get('isValid');
          var application = self.controllerFor('application');
          if (validation === true) {
            self.set('displayErrors', false);
            application.set('verstaerkungInvalid', false);

            self.controllerFor('supercontroller').resetApplication();

            var verstaerkung = self.controllerFor('application').get('model').verstaerkung.objectAt(0);

            verstaerkung.set('breite_ReI', parseFloat(self.get('htbreite')).toFixed(1));
            verstaerkung.set('hoehe_ReI', parseFloat(self.get('hthoehe')).toFixed(1));
            verstaerkung.set('profilBreite', parseFloat(self.get('profilBreite')).toFixed(1));
            verstaerkung.set('profilHoehe', parseFloat(self.get('profilHoehe')).toFixed(1));
          } else {
            self.set('displayErrors', true);
            application.set('verstaerkungInvalid', true);
          }
        });
      },

      htbreiteIsSelected: function htbreiteIsSelected() {
        var breiteMaterial2Aktiv = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('breiteMaterial2Aktiv');
        this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('breiteMaterial2Aktiv', !breiteMaterial2Aktiv);

        if (this.get('htbreite') !== "") {
          var htbreitework = parseFloat(this.get('htbreite').replace(",", "."));
          var htbreiterounded = htbreitework.toFixed(1);
          this.set('htbreite', htbreiterounded);
          this.send('validation', htbreiterounded, {
            target: {
              name: "htbreite"
            }
          });
        }

        if (breiteMaterial2Aktiv === false) {
          document.getElementsByName('htbreite')[0].setSelectionRange(0, this.get('htbreite').length);
        }
      },

      hthoeheIsSelected: function hthoeheIsSelected() {
        var hoeheMaterial2Aktiv = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('hoeheMaterial2Aktiv');
        this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('hoeheMaterial2Aktiv', !hoeheMaterial2Aktiv);

        if (this.get('hthoehe') !== "") {
          var hthoehework = parseFloat(this.get('hthoehe').replace(",", "."));
          var hthoeherounded = hthoehework.toFixed(1);
          this.set('hthoehe', hthoeherounded);
          this.send('validation', hthoeherounded, {
            target: {
              name: "hthoehe"
            }
          });
        }

        if (hoeheMaterial2Aktiv === false) {
          document.getElementsByName('hthoehe')[0].setSelectionRange(0, this.get('hthoehe').length);
        }
      },

      profilBreiteIsSelected: function profilBreiteIsSelected() {
        var breiteMaterial2Aktiv = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('breiteMaterial2Aktiv');
        this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('breiteMaterial2Aktiv', !breiteMaterial2Aktiv);

        if (this.get('profilBreite') !== "") {
          var profilBreitework = parseFloat(this.get('profilBreite').replace(",", "."));
          var profilBreiterounded = profilBreitework.toFixed(1);
          this.set('profilBreite', profilBreiterounded);
          this.send('validation', profilBreiterounded, {
            target: {
              name: "profilBreite"
            }
          });
        }

        if (breiteMaterial2Aktiv === false) {
          document.getElementsByName('profilBreite')[0].setSelectionRange(0, this.get('profilBreite').length);
        }
      },

      profilHoeheIsSelected: function profilHoeheIsSelected() {
        var hoeheMaterial2Aktiv = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('hoeheMaterial2Aktiv');
        this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('hoeheMaterial2Aktiv', !hoeheMaterial2Aktiv);

        if (this.get('profilHoehe') !== "") {
          var profilHoehework = parseFloat(this.get('profilHoehe').replace(",", "."));
          var profilHoeherounded = profilHoehework.toFixed(1);
          this.set('profilHoehe', profilHoeherounded);
          this.send('validation', profilHoeherounded, {
            target: {
              name: "profilHoehe"
            }
          });
        }

        if (hoeheMaterial2Aktiv === false) {
          document.getElementsByName('profilHoehe')[0].setSelectionRange(0, this.get('profilHoehe').length);
        }
      },

      removeClass: function removeClass(element) {
        var self = this;
        self.set(element, false);
      }

    }, // Ende actions

    // hilfeTexte obeserver

    observes_ntholzHilfetext: (function () {
      var self = this;
      var hilfetextArray = self.controllerFor('supercontroller').getHelpText("Bauteile", "ntholz", self.get('holz').toString());
      self.controllerFor("supercontroller").setHilfetexte("verstaerkung", hilfetextArray);
    }).observes('holz', 'initTrigger'),

    observes_ntfklHilfetext: (function () {
      var self = this;
      var hilfetextArray = self.controllerFor('supercontroller').getHelpText("Bauteile", "ntfkl", "0");
      self.controllerFor("supercontroller").setHilfetexte("verstaerkung", hilfetextArray);
    }).observes('fkl', 'initTrigger'),

    observes_te2vorgebohrtHilfetext: (function () {
      var self = this;
      var hilfetextArray = self.controllerFor('supercontroller').getHelpText("Bauteile", "te2vorgebohrt", "0");
      if (Number(self.get("holz")) !== 30) {
        self.controllerFor("supercontroller").setHilfetexte("verstaerkung", hilfetextArray);
      }
    }).observes('teReIVorgebohrt', 'initTrigger'),

    // hilfeTexte display properties

    display_ntholzHilfetext: (function () {
      var self = this;
      return self.controllerFor("supercontroller").setHilfetexteDisplayed(self.get('ntholzHilfetext'));
    }).property('ntholzHilfetext'),

    display_ntfklHilfetext: (function () {
      var self = this;
      return self.controllerFor("supercontroller").setHilfetexteDisplayed(self.get('ntfklHilfetext'));
    }).property('ntfklHilfetext'),

    display_te2vorgebohrtHilfetext: (function () {
      var self = this;
      return self.controllerFor("supercontroller").setHilfetexteDisplayed(self.get('te2vorgebohrtHilfetext'));
    }).property('te2vorgebohrtHilfetext')

  });

});