define('m14/controllers/verbundstellen', ['exports', 'ember', 'ember-validations', 'ember-i18n'], function (exports, Ember, EmberValidations, ember_i18n) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend(EmberValidations['default'], {
    debug: true,
    openFromFile: false,
    c26: false,
    displayErrors: true,
    material: {},
    honk: null,
    ttHoeheHT: Ember['default'].computed('i18n.locale', function () {

      return this.get('i18n').t('wertebereich') + "6.0 - 999.0 [cm]";
    }),
    ttBreiteHT: Ember['default'].computed('i18n.locale', function () {

      return this.get('i18n').t('wertebereich') + "3.0 - 999.0 [cm]";
    }),
    ttProfilBreite: Ember['default'].computed('i18n.locale', function () {

      return this.get('i18n').t('wertebereich') + "0.2 - 999.0 [cm]";
    }),
    ttProfilHoehe: Ember['default'].computed('i18n.locale', function () {

      return this.get('i18n').t('wertebereich') + "4.0 - 999.0 [cm]";
    }),
    i18n: Ember['default'].inject.service(),

    vortriebsrichtungen: [],
    vortriebsrichtung: 0,
    verbindungsmittelLagen: [],
    lageVerbindungsmittel: 0,

    indexContentTextboxen: 0,

    init: function init() {
      var self = this;
      this._super();

      var applicationdata = this.controllerFor('application').get('model').application.objectAt(0);

      var lang = applicationdata.get('countrycode');

      this.setSelectFieldsContent();

      // this.set('vortriebsrichtungen', vortriebsrichtungenDB.vortriebsrichtungen_de);
      this.set('vortriebsrichtung', true);

      // this.set('verbindungsmittelLagen', lageVerbindungsmittelDB.lageVerbindungsmittel_de);
      this.set('lageVerbindungsmittel', 2);
    },

    setValues: function setValues(values) {

      var self = this;

      this.set('openFromFile', true);

      this.set('systemlaenge', values.LSys);

      this.set('vortriebsrichtung', values.RichtungVH);

      if (values.schraubenpaar) {
        self.set('lageVerbindungsmittel', 0);
      } else {
        if (Number(values.AngleFastenerGRAD) === 45) {
          self.set('lageVerbindungsmittel', 1);
        } else {
          self.set('lageVerbindungsmittel', 2);
        }
      }

      // this.send('validation');

      this.set('openFromFile', false);
    },

    setSelectFieldsContent: function setSelectFieldsContent() {
      var self = this;
      self.setVortriebsrichtungen();
      self.setVerbindungsmittelLagen();
    },

    setVortriebsrichtungen: function setVortriebsrichtungen() {
      var self = this;
      var indices = [true, false];
      if (self.get('indexContentTextboxen') !== 0) {
        self.set('vortriebsrichtungen', self.getSelectFieldContent('vortriebsrichtungenAlternative', indices));
      } else {
        self.set('vortriebsrichtungen', self.getSelectFieldContent('vortriebsrichtungen', indices));
      }
    },

    setVerbindungsmittelLagen: function setVerbindungsmittelLagen() {
      var self = this;
      var indices = [0, 1, 2];
      if (self.get('indexContentTextboxen') === 0 || self.get('indexContentTextboxen') === 1) {
        self.set('verbindungsmittelLagen', self.getSelectFieldContent('verbindungsmittelLagen', indices));
      } else {
        self.set('verbindungsmittelLagen', self.getSelectFieldContent('verbindungsmittelLagenAlternative', indices));
      }
    },

    getSelectFieldContent: function getSelectFieldContent(db, indexes) {
      var self = this;
      var tmp = [];
      var dbContent = self.getDBContent(db);
      for (var i = 0; i < indexes.length; i++) {
        for (var k = 0; k < dbContent.length; k++) {
          if (dbContent[k].ind === indexes[i]) {
            tmp.push({ ind: dbContent[k].ind, name: this.get('i18n').t(dbContent[k].name), disabled: dbContent[k].disabled });
          }
        }
      }

      return tmp;
    },

    getDBContent: function getDBContent(db) {
      var dbContent = [];
      switch (db) {
        case 'vortriebsrichtungen':
          dbContent = vortriebsrichtungenDB.vortriebsrichtungen_de;
          break;
        case 'vortriebsrichtungenAlternative':
          dbContent = vortriebsrichtungenDB.vortriebsrichtungen_de_alternative;
          break;
        case 'verbindungsmittelLagen':
          dbContent = lageVerbindungsmittelDB.lageVerbindungsmittel_de;
          break;
        case 'verbindungsmittelLagenAlternative':
          dbContent = lageVerbindungsmittelDB.lageVerbindungsmittel_de_alternative;
          break;
      }
      return dbContent;
    },

    getValueFromSelectField: function getValueFromSelectField(contentArray, index) {
      var self = this;

      var tmp = "";
      var len = contentArray.length;

      for (var i = 0; i < len; i++) {
        if (contentArray[i].id === index) {
          tmp = contentArray[i].name;
        }
      }
      return tmp;
    },

    watchComboboxes: (function () {
      var self = this;

      self.controllerFor('supercontroller').resetApplication();

      var x3d = self.controllerFor('application').get('model').x3ddefault.objectAt(0);
      var bauteile = x3d.get('bauteile');
      var winkel = 1.5708;

      var verbundstellen = this.controllerFor('application').get('model').verbundstellen.objectAt(0);

      if (self.get('lageVerbindungsmittel') === 0) {
        verbundstellen.set('AngleFastenerGRAD', "45");
        verbundstellen.set('schraubenpaar', true);
        winkel = 0.7854;
        x3d.set('schraubenGekreuzt', true);
      } else if (self.get('lageVerbindungsmittel') === 1) {
        verbundstellen.set('AngleFastenerGRAD', "45");
        verbundstellen.set('schraubenpaar', false);
        winkel = 0.7854;
        x3d.set('schraubenGekreuzt', false);
      } else if (self.get('lageVerbindungsmittel') === 2) {
        verbundstellen.set('AngleFastenerGRAD', "90");
        verbundstellen.set('schraubenpaar', false);
        x3d.set('schraubenGekreuzt', false);
      }

      var geometrie = self.controllerFor('geometrie');

      self.setSchraubenwinkel(winkel);

      if (self.get('vortriebsrichtung') === true) {

        verbundstellen.set('RichtungVH', true);
        x3d.set('vortriebsrichtungVT', true);
      } else if (self.get('vortriebsrichtung') === false) {
        verbundstellen.set('RichtungVH', false);
        x3d.set('vortriebsrichtungVT', false);
      }

      if (x3d.get('ergebnisGeladen') === true) {
        geometrie.resetGrafikToDefault();
      }
      geometrie.setSchraubenlaenge();
      geometrie.setSchraubenPosition();
    }).observes('vortriebsrichtung', 'lageVerbindungsmittel'),

    setSchraubenwinkel: function setSchraubenwinkel(winkel) {
      var application = this.controllerFor('application');
      var x3d = application.get('model').x3ddefault.objectAt(0);
      var bauteile = application.get('model').x3ddefault.objectAt(0).get('bauteile');
      var count = 0;

      bauteile.forEach(function (item) {
        if (item.get('typ') === 'standardSchraube') {
          count++;
          if (count > 8) {
            return;
          }
          var richtung = Math.abs(item.get('rotations').objectAt(0).get('z')) / item.get('rotations').objectAt(0).get('z');

          item.get('rotations').objectAt(0).set('z', richtung * winkel);
        }
      });
    }
  });

});