define('m14/controllers/supercontroller', ['exports', 'ember', 'ember-i18n'], function (exports, Ember, ember_i18n) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({

    i18n: Ember['default'].inject.service(),

    testAusgabe: function testAusgabe(text) {},

    resetApplication: function resetApplication() {
      var self = this;

      var applicationdata = self.controllerFor('application').get('model').application.objectAt(0);
      applicationdata.set('treeLoaded', false);
      applicationdata.set('initialized', false);
      applicationdata.set('pdfErstellt', false);

      var application = self.controllerFor('application');
      application.set('verbindungsmittelInvalid', true);

      var downloadcenter = self.controllerFor('downloadcenter');
      downloadcenter.set('pdfErstellt', false);
      downloadcenter.set('etaFehlt', true);
      downloadcenter.set('katalogFehlt', true);
    },

    uebergabedatenErzeugen: function uebergabedatenErzeugen() {

      var self = this;
      var application = self.controllerFor('application');

      //var applicationdata = application.model.objectAt(0);
      var applicationdata = application.get('model').application.objectAt(0);
      var applicationdataJSON = JSON.stringify(applicationdata);

      var projektdatendata = application.get('model').projektdaten.objectAt(0);
      var projektdatendataJSON = JSON.stringify(projektdatendata);

      var geometriedata = application.get('model').geometrie.objectAt(0);
      var geometriedataJSON = JSON.stringify(geometriedata);

      var verstaerkungdata = application.get('model').verstaerkung.objectAt(0);
      var verstaerkungdataJSON = JSON.stringify(verstaerkungdata);

      var verbundstellendata = application.get('model').verbundstellen.objectAt(0);
      var verbundstellendataJSON = JSON.stringify(verbundstellendata);

      var lasteinwirkungdata = application.get('model').lasteinwirkung.objectAt(0);
      var lasteinwirkungdataJSON = JSON.stringify(lasteinwirkungdata);

      if (self.debug) {
        console.log("projektdatendata: " + projektdatendataJSON);
        console.log("applicationdataJSON: " + applicationdataJSON);
        console.log("geometriedataJSON: " + geometriedataJSON);
        console.log("lasteinwirkungdataJSON: " + lasteinwirkungdataJSON);
      }

      var uebergabedaten = $.extend(true, JSON.parse(applicationdataJSON), JSON.parse(projektdatendataJSON), JSON.parse(geometriedataJSON), JSON.parse(verstaerkungdataJSON), JSON.parse(verbundstellendataJSON), JSON.parse(lasteinwirkungdataJSON));

      var bestandPredrilled = false;
      if (geometriedata.get('MatKeyBestandstraeger') == 12 || geometriedata.get('MatKeyBestandstraeger') == 30) {
        bestandPredrilled = true;
      } else {
        bestandPredrilled = false;
      }

      var verstaerkungPredrilled = false;
      if (verstaerkungdata.get('MatKeyVerstaerkungstraeger') == 12 || verstaerkungdata.get('MatKeyVerstaerkungstraeger') == 30) {
        verstaerkungPredrilled = true;
      } else {
        verstaerkungPredrilled = false;
      }

      var timberelementold = {
        "Querschnittswerte": {
          "b": geometriedata.get('TimberElement1_b'),
          "h": geometriedata.get('TimberElement1_h'),
          "t_Fastener": geometriedata.get('TimberElement1_b'),
          "QuerschnittsID": "1"
        },
        "alphaRAD_KraftFaser": "0.0",
        "alphaRAD_vmFaser": "90.0",
        "betaRAD_vmFurnier": "90.0",
        "vorgebohrt": geometriedata.get('Bestandstraeger_vorgebohrt'),
        "MatKey": geometriedata.get('MatKeyBestandstraeger'),
        "FKLKey": geometriedata.get('FKLKeyBestandstraeger')
      };

      var timberelementrei = {
        "Querschnittswerte": {
          "b": verstaerkungdata.get('breite_ReI'),
          "h": verstaerkungdata.get('hoehe_ReI'),
          "t_Fastener": verstaerkungdata.get('breite_ReI'),
          "QuerschnittsID": "1"
        },
        "alphaRAD_KraftFaser": "0.0",
        "alphaRAD_vmFaser": "90.0",
        "betaRAD_vmFurnier": "90.0",
        "vorgebohrt": verstaerkungdata.get('Verstaerkungstraeger_vorgebohrt'),
        "MatKey": verstaerkungdata.get('MatKeyVerstaerkungstraeger'),
        "FKLKey": verstaerkungdata.get('FKLKeyVerstaerkungstraeger')
      };

      var fkl_key = -1;

      try {
        fkl_key = enums.Stahl.findBy("typ", verstaerkungdata.get('Bezeichnung')).id;
      } catch (e) {}

      var steelelement = {
        "Querschnittswerte": {
          "b": verstaerkungdata.get('profilBreite'),
          "h": verstaerkungdata.get('profilHoehe')
        },
        "vorgebohrt": false,
        "FlachStahl": verstaerkungdata.get('Flachstahl'),
        "ProfilName": verstaerkungdata.get('ProfilName'),
        "f_yk": verstaerkungdata.get('f_yk'),
        "Bezeichnung": verstaerkungdata.get('Bezeichnung'),
        "FKLKey": fkl_key
      };

      var externalloads = [lasteinwirkungdata.get('staendigeEinwirkungen'), // ständige einwirkungen
      lasteinwirkungdata.get('verEinw1'), // ver. einw'). 1
      lasteinwirkungdata.get('verEinw2') // ver. einw. 2
      ];

      var standardloads = [lasteinwirkungdata.get('eigengewicht'), // vermutlich auswahl "einfeldträger"
      lasteinwirkungdata.get('kled1'), // kled menu 1
      lasteinwirkungdata.get('kled2') // kled menu 2
      ];

      uebergabedaten.bestandstraeger = timberelementold;
      uebergabedaten.verstaerkungstraeger = timberelementrei;
      uebergabedaten.stahltraeger = steelelement;
      uebergabedaten.externeLasten = externalloads;
      uebergabedaten.lastIDs = standardloads;

      return uebergabedaten;
    },

    erzeugeAufrufzusatz: function erzeugeAufrufzusatz(zweiterzusatz) {

      // Aufrufe	Zusatz1	Zusatz2
      // initialisiereBerechnung	"?"+kennungland_loadsNA_timberNA
      // getKompaktergebnis	"?"+kennungland_loadsNA_timberNA	"_"+artikelnummer.replace(' ','+')
      // erzeugeSchraubenliste	"?"+kennungland_loadsNA_timberNA
      // erzeugeAusgabe	"?"+kennungland_loadsNA_timberNA	"_"+artikelnummer.replace(' ','+')
      // erzeugeDXF	"?"+kennungland_loadsNA_timberNA	"_"+artikelnummer.replace(' ','+')
      // speichereBerechnung	"?"+kennungland_loadsNA_timberNA
      //
      // Beispiel
      // erzeugeAusgabe/?CH_CH_DE_0170+350+45

      var self = this;

      var application = self.controllerFor('application');
      var applicationdata = application.get('model').application.objectAt(0);
      var verbindungsmittel = self.controllerFor('verbindungsmittel');

      var zusatzString = "?" + applicationdata.get("kennungland") + "_" + applicationdata.get("loadsNA") + "_" + applicationdata.get("timberNA");

      if (zweiterzusatz === true) {

        var artikelnummer;

        if (typeof verbindungsmittel.get('selectedNodes')[0] === 'undefined') {
          artikelnummer = "Keine Artikel ausgewaehlt";
        } else {
          artikelnummer = verbindungsmittel.get('selectedNodes')[0].data.ArtNr;
        }

        zusatzString = zusatzString + "_" + artikelnummer.replace(/ /g, '+');
      }

      return zusatzString;
    },

    getHelpText: function getHelpText(reiter, steuerElemente, bedingung) {
      return hilfeTexteDB_wuerth.reiter.findBy("bezeichnung", reiter).steuerElemente.findBy("bezeichnung", steuerElemente).hilfeTexte.findBy("abhaengigkeit", bedingung).hilfetextfelder;
    },

    setHilfetexte: function setHilfetexte(controllerName, hilfetextArray) {
      var self = this;
      var controller = self.controllerFor(controllerName);
      for (var i = 0; i < hilfetextArray.length; i++) {
        if (hilfetextArray[i].text !== controller.get(hilfetextArray[i].bezeichnung)) {
          controller.set(hilfetextArray[i].bezeichnung + "Focused", true);
        }
        // controller.set(hilfetextArray[i].bezeichnung, hilfetextArray[i].text);
        controller.set(hilfetextArray[i].bezeichnung, self.get('i18n').t(hilfetextArray[i].text.toString()));
      }
    },

    setHilfetexteDisplayed: function setHilfetexteDisplayed(textProperty) {
      var self = this;
      // if(textProperty === "" || typeof self.get('i18n').t(textProperty) === "undefined" || self.get('i18n').t(textProperty) === ""){

      if (textProperty === "" || textProperty.toString().substring(0, 20) === "Missing translation:" || typeof textProperty === "undefined" || self.get('i18n').t(textProperty) === "") {
        return false;
      } else {
        return true;
      }
    },

    logaufruf: function logaufruf(_aufruf, _status, _showOutput, _logAnServerSendenErzwingen) {
      var self = this;
      var application = self.controllerFor('application');
      var applicationdata = application.get('model').application.objectAt(0);

      var date = new Date();
      var datuminticks = date.getTime() * 10000 + 621355968000000000;

      if (_showOutput) {
        console.log("***** logaufruf von *****");
        console.log(_aufruf);
      }

      var tc = application.get('model').application.objectAt(0).get('timberCode').replace(" ", "").substring(0, 3);
      var lc = application.get('model').application.objectAt(0).get('loadsCode').replace(" ", "").substring(0, 3);

      var guid = "00000000-0000-0000-0000-000000000000";
      var guidlength = guid.length;
      var cidlength = application.get('model').application.objectAt(0).get('Calculation_ID').length;
      guid = guid.substring(0, guidlength - cidlength) + application.get('model').application.objectAt(0).get('Calculation_ID');

      var logdaten = {
        "datum": datuminticks,
        "bid": guid,
        "request": 0,
        "firma": enums.Firma.findBy("bezeichnung", application.get('model').application.objectAt(0).get('firm')).id,
        "modul": enums.Modul.findBy("bezeichnung", "m14").id,
        "bemessung": enums.NormBemessung.findBy("bezeichnung", tc).id,
        "bemessungNAD": enums.Sprache.findBy("bezeichnung", application.get('model').application.objectAt(0).get('timberNA').toLowerCase()).id,
        "lastannahmen": enums.NormLastannahmen.findBy("bezeichnung", lc).id,
        "lastannahmenNAD": enums.Sprache.findBy("bezeichnung", application.get('model').application.objectAt(0).get('loadsNA').toLowerCase()).id,
        "eingabeSprache": enums.Sprache.findBy("bezeichnung", application.get('model').application.objectAt(0).get('spracheinput').toLowerCase()).id,
        "ausgabeSprache": enums.Sprache.findBy("bezeichnung", application.get('model').application.objectAt(0).get('spracheoutput').toLowerCase()).id,
        "kennungLand": enums.Sprache.findBy("bezeichnung", application.get('model').application.objectAt(0).get('kennungland').toLowerCase()).id,
        "katalog": enums.Sprache.findBy("bezeichnung", application.get('model').application.objectAt(0).get('katalog').toLowerCase()).id,
        "methode": enums.Methode.findBy("bezeichnung", _aufruf).id,
        "userIp": null,
        "userName": application.get('model').application.objectAt(0).get('userName'),
        "url": null,
        "status": _status,
        "artikelnummer": application.get('model').application.objectAt(0).get('selectedScrew')
      };

      if (_showOutput) {
        console.log("zu lockende Daten:");
        console.log(logdaten);
      }

      var form = new FormData();
      form.append("", JSON.stringify(logdaten));

      var environment = externalConfig.environments[application.get('environment')];
      var url = externalConfig[environment].uriLogservice;

      var liveVersion = application.get('environment').search('dev') !== -1 ? false : true;

      if (liveVersion === true || _logAnServerSendenErzwingen === true) {

        $.ajax({
          "type": "POST",
          "url": url,
          "processData": false,
          "contentType": false,
          data: form

        }).done(function (data, statusText, xhr) {
          if (_showOutput) {
            console.log("Daten gelockt: " + data);
            // var status = xhr.status;                //200
            // console.log("statusText");
            // console.log(statusText);
            // console.log("status");
            // console.log(status);
            console.log(" ");
          }
        });
      }
      // testen von der Browserkonsole --> M14.__container__.lookup('controller:supercontroller').logaufruf('erzeugeDXF', 4444, true, true);
    }

  });

});